import * as React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import logo from "../assets/images/logo_1100_108.png"
import { Helmet } from "react-helmet"

const App = styled.div`
  margin: 0;
`

const Content = styled("div")``

const Header = styled.header`
  position: relative;
  min-height: 76px;
  margin-bottom: 0;
  border: 1px solid transparent;
  background-color: rgba(37, 50, 67, 1);
  width: auto;
`

const Footer = styled.footer`
  background: 0;
  border-top: 1px solid #f0f0f2;
  margin: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
`

const FooterList = styled.ul`
  margin-top: 2rem;
  list-style: none;
  text-align: center;
  width: 100%;
  font-size: 0.875rem;
`

const SP = styled.div`
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1080px;
  align-items: center;
`

const HH = styled.h3`
  color: white;
  display: inline;
  margin-left: 40px;
`

const Logo = styled.img`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 96px;
  width: 96px;
  height: 96px;
  margin: 0;
  vertical-align: middle;
`
const link = css`
  text-decoration: none;
  color: inherit;
`

const FooterListItem = styled.li`
  display: inline-block;
  color: #78757a;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`

type LayoutProps = {
  children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => (
  <App>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Alexa open &quot;Be Happy!&quot;</title>
      <link rel="canonical" href="http://happyspce.com" />
    </Helmet>
    <Header>
      <SP>
        <Link to="/" css={link}>
          <Logo src={logo} />
        </Link>
        <Link to="/" css={link}>
          <HH>Be Happy!</HH>
        </Link>
      </SP>
    </Header>
    <Content>{children}</Content>
    <Footer>
      <FooterList>
        <FooterListItem>
          <Link to="/privacy-policy/" css={link}>
            Privacy Policy
          </Link>
        </FooterListItem>
        <FooterListItem>
          <Link to="/" css={link}>
            Happyspace.com
          </Link>
        </FooterListItem>
      </FooterList>
    </Footer>
  </App>
)

export default Layout
